import React from 'react'
import { useTranslation } from 'react-i18next'
import Layout from 'src/theme/Layout'
import BubbleBackground from 'src/components/BubbleBackground'
import VerticalAlignContainer from 'src/components/VerticalAlignContainer'

import Paper from 'src/components/atoms/Paper'
import PaperTitle from 'src/components/atoms/PaperTitle'
import AuthFormContainer from 'src/components/AuthFormContainer'
import LogInForm from 'src/features/auth/LoginForm'

export const Login = props => {
  const { t } = useTranslation()

  return (
    <Layout>
      <BubbleBackground>
        <VerticalAlignContainer>
          <AuthFormContainer>
            <Paper>
              <PaperTitle>{t('logInPage.title')}</PaperTitle>
              <LogInForm />
            </Paper>
          </AuthFormContainer>
        </VerticalAlignContainer>
      </BubbleBackground>
    </Layout>
  )
}

export default Login

import styled from 'styled-components'

export default styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: ${props => props.theme.dimensions.authFormWidth};

  ${props => props.theme.breakpointsMedia.mobile} {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 10px;
  }
`

import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { META } from 'src/utils/seo'
import PATHS from 'src/utils/paths'
import {
  validateLoginField,
  validateLoginValues
} from 'src/features/auth/duck/schema'

import { loginUserRoutine } from 'src/features/auth/duck/actions'
import { isAuthLoading } from 'src/features/auth/duck/selectors'

import Form from 'src/components/atoms/Form'
import Input from 'src/components/atoms/Input'
import Metadata from 'src/components/Metadata'
import Button from 'src/components/atoms/Button'

const emptyValues = {
  email: '',
  password: ''
}

export const LoginForm = props => {
  //  REDUX
  const dispatch = useDispatch()
  const isLoading = useSelector(isAuthLoading)
  const loginUser = useCallback(
    payload => dispatch(loginUserRoutine(payload)),
    [dispatch]
  )

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)

  useEffect(() => {
    validateLoginValues(values, _validateSchema)
  }, [values])

  const { t } = useTranslation()
  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    loginUser(values)
  }

  return (
    <div>
      <Metadata meta={META.logIn} />
      <Form onSubmit={handleSubmit}>
        <Input
          required
          name='email'
          label={t('form.email.label')}
          onChange={handleOnChange}
          validate={validateLoginField(values)}
        />
        <Input
          required
          name='password'
          type='password'
          label={t('form.password.label')}
          onChange={handleOnChange}
          validate={validateLoginField(values)}
        />
        <Link to={PATHS.resetPasswordInit}>
          {t('logInPage.resetPasswordCTA')}
        </Link>
        <ButtonContainer>
          <Button
            type='submit'
            disabled={isLoading || !valid}
            onClick={handleSubmit}
          >
            {t('logInPage.loginCTA')}
          </Button>
        </ButtonContainer>
      </Form>
    </div>
  )
}

export default LoginForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
